import React from 'react';
import IndexLayout from 'layouts';

import { Components as SiteDataComponents } from 'sitedata';
import { styled } from 'styles';

const Sell = () => (
  <IndexLayout>
    <StyledHeader />
    <SiteDataComponents.SellPage />
    <SiteDataComponents.Footer />
  </IndexLayout>
);

export default Sell;

const StyledHeader = styled(SiteDataComponents.Header)`
  background: #ffffff;
`;
